import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({
  title,
  description,
  image,
  article,
  blogCreatedAt,
  blogUpdatedAt,
  location,
  relatedCompanies,
  noTitlePrefix = false,
  rating,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
    blogAuthor,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    // image: `${siteUrl}${image || defaultImage}`,
    image: image || `${siteUrl}${defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={noTitlePrefix ? undefined : titleTemplate}
    >
      <html lang="en" />
      {/* {
        <script
          data-ad-client="ca-pub-6369354476906630"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
      } */}
      {(article ? true : null) && (
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": `${seo.url}`,
              },
              headline: `${seo.title}`,
              description: `${seo.description}`,
              image: `${seo.image}`,
              author: {
                "@type": "Organization",
                name: `${blogAuthor}`,
              },
              publisher: {
                "@type": "Organization",
                name: `${blogAuthor}`,
                logo: {
                  "@type": "ImageObject",
                  url:
                    "https://images.ctfassets.net/z7pyyvho21dm/56G3Sy04rYABwiRJE0Qpd5/93c58230cd554417689228cab8a4f077/Screenshot_2021-04-26_132720.png?w=680&h=144&q=50&fm=webp",
                },
              },
              datePublished: `${blogCreatedAt}`,
              dateModified: `${blogUpdatedAt}`,
            },
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Blog",
                  item: "https://snappywebdesign.net/blog",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: `${seo.title}`,
                  item: `${seo.url}`,
                },
              ],
            }
          )}
        </script>
      )}
      {(location ? true : null) && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: `Who are the best web design companies in ${location}?`,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: `Snappy Web Design was rated among the best web design companies of 2021. ${relatedCompanies} were also highly rated by Clutch.co`,
                },
              },
              {
                "@type": "Question",
                name:
                  "How much does it cost to hire someone to build a website?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text:
                    "On average, it costs around $200 to build a website, with an ongoing cost of around $20 per month to maintain it. This estimate is higher if you hire a designer or developer – Snappy offers the cheapest rates around $1,000 compared to the industry average of $6,000.",
                },
              },
              {
                "@type": "Question",
                name: "Should I pay someone to build my website?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text:
                    "On one hand, if you're in a rush and only need a basic one-page site, your best bet is probably just to do it yourself. On the other hand, if you need your website up quickly and it needs a lot of features you should probably pay someone. ... This will allow you to create a more complex site and not have to rush.",
                },
              },
              {
                "@type": "Question",
                name: "Is it better to hand code a website?",
                acceptedAnswer: {
                  "@type": "Answer",
                  text:
                    "Your own code is always way more secure than Website builder. CMS always use same code for security purposes, which is attractively targeted by hackers. Making changed through a custom CMS typically takes more time, but Snappy makes it just as fast.",
                },
              },
            ],
          })}
        </script>
      )}
      {(rating ? true : null) && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Product",
            name: "Website Development",
            image: "https://snappywebdesign.net/images/snappylogo.png",
            description:
              "Custom small business web design, development, and SEO services starting at $1,000.",
            url: `${seo.url}`,
            brand: {
              "@type": "Brand",
              name: "Snappy Web Design",
              logo: "https://snappywebdesign.net/images/snappylogo.png",
            },
            sku: "web001",
            gtin8: "g_web001",
            offers: {
              "@type": "Offer",
              url: "https://snappywebdesign.net/websitedesign/",
              priceCurrency: "USD",
              price: "1000",
              priceValidUntil: "2021-10-31",
              availability: "https://schema.org/InStock",
              itemCondition: "https://schema.org/NewCondition",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "5",
              ratingCount: "3",
              reviewCount: "3",
            },
            review: [
              {
                "@type": "Review",
                reviewRating: {
                  "@type": "Rating",
                  ratingValue: "5",
                },
                author: {
                  "@type": "Person",
                  name: "Jesse Burke",
                },
              },
              {
                "@type": "Review",
                reviewRating: {
                  "@type": "Rating",
                  ratingValue: "5",
                },
                author: {
                  "@type": "Person",
                  name: "Sam Love",
                },
              },
              {
                "@type": "Review",
                reviewRating: {
                  "@type": "Rating",
                  ratingValue: "5",
                },
                author: {
                  "@type": "Person",
                  name: "Tonja Andreatta",
                },
              },
            ],
          })}
        </script>
      )}

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta
        name="facebook-domain-verification"
        content="9lvwbobth5qyfr2gw3di51e1rajb6o"
      />
      {seo.url && <meta property="og:url" content={seo.url} />}

      {/* {(article ? true : null) && <meta property="og:type" content="article" />} */}
      {article ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
        blogAuthor: author
      }
    }
  }
`
